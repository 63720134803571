<template>
    <div class="home">

        <section class="bgimage d-flex justify-content-center align-items-center text-white text-center">
            <b-container>
                <b-row>
                    <b-col>
                        <h1>Japans Restaurant</h1>
                        <h4>Mountain Spring Sushi King</h4>
                        <p class="text-white">
                            Groen van Prinstererweg 19-21 2982 VA Ridderkerk
                        </p>
                        <b-button href="tel:0180 418 414" pill variant="danger"
                                  class="text-uppercase font-weight-bold p-3 mt-3">
                            Tafel Reserveren
                        </b-button>
<!--                        <b-button to="/bezorgen" pill variant="danger" class="text-uppercase font-weight-bold p-3 mt-3">-->
<!--                            Eten Online Bestellen-->
<!--                        </b-button>-->
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <div>

            <!-- Corona melding -->
            <section class="section-1 py-5 d-flex justify-content-center align-items-center">
                <b-img v-parallax="0.2" class="d-none d-lg-block" src="@/assets/japanese-flowers.png" fluid
                       alt="Responsive image"/>
                <b-container>
                    <b-row>
                        <b-col sm="12" lg="10" offset-lg="1" class="p-5">
                            <div class="section-title">
                                <h1>Mededeling</h1>
                            </div>
                            <p>
                              Kom gezellig langs en probeer de overheerlijke gerechtjes van
                              onze nieuwe kaart.
                            </p>
<!--                            <p>Wij zijn weer beperkt geopend! Wij volgen hierbij nog wel de richtlijnen van het RIVM.</p>-->
<!--                            <p>Het is het nog steeds mogelijk om telefonisch of via de website te bestellen.</p>-->
<!--                            <p>Wij proberen u zoveel mogelijk weer als vanouds van dienst te zijn.</p>-->
<!--                            <b-button href="tel:0180 418 414" pill variant="secondary" class="text-uppercase font-weight-bold mt-3">-->
<!--                              Tafel reserveren-->
<!--                            </b-button>-->
<!--                            <p>-->
<!--                              De strenge maatregelen om de verspreiding van het nieuwe Coronavirus tegen-->
<!--                              te gaan houden Nederland in een ijzeren greep. Vanwege de verspreiding van het Coronavirus-->
<!--                              zijn we helaas genoodzaakt om onze deuren voorlopig te sluiten.-->
<!--                            </p>-->
<!--                            <p>Wel is het mogelijk om telefonisch of via de website te bestellen.</p>-->
<!--                            <p>-->
<!--                              Wij proberen u zoveel mogelijk van dienst te zijn. Wellicht zal dit leiden tot enige vertraging.-->
<!--                              Hiervoor onze excuses. Wij hopen dat wij snel weer op de oude, gastvrije manier u te mogen ontvangen.-->
<!--                            </p>-->
<!--                            <p>-->
<!--                              Veel gezondheid toegewenst.-->
<!--                            </p>-->
                            <p>Om een plek te garanderen adviseren we ruim op tijd te reserveren om teleurstellingen te voorkomen.</p>
                            <div class="d-flex align-items-center">
                              <b-button href="tel:0180 418 414" pill variant="danger" class="text-uppercase font-weight-bold mt-3">
                                Tafel reserveren
                              </b-button>
                              <a href="/bezorgen" class="text-uppercase text-danger font-weight-bold ml-3 mt-3">
                                Eten Online Bestellen
                              </a>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </section>

            <section>
                <b-container fluid>
                    <b-row>
                        <b-col sm="12" lg="6" class="d-flex justify-content-center align-items-center p-5 bg-white">
                            <div>
                                <div class="section-title">
                                    <h1>All you can eat</h1>
                                    <h4>Prijzen</h4>
                                </div>
                                <p class="font-weight-bold">Diner</p>
                                <div class="d-flex">
                                    <p>Maandag t/m Donderdag</p>
                                    <span class="ml-auto text-danger">&euro;35,00*</span>
                                </div>
                                <div class="d-flex">
                                    <p>Maandag t/m Donderdag 65+</p>
                                    <span class="ml-auto text-danger">&euro;31,00*</span>
                                </div>
                                <div class="d-flex">
                                    <p>
                                      Vrijdag t/m Zondag en Feestdagen <br>
                                      <small>(1e zitting van 17:00 uur tot 19:45 uur / 2e zitting van 20:00 uur tot eind)</small>
                                    </p>
                                    <span class="ml-auto text-danger">&euro;37,50*</span>
                                </div>
                                <div class="d-flex">
                                    <p>Vrijdag t/m Zondag en Feestdagen 65+</p>
                                    <span class="ml-auto text-danger">&euro;33,50*</span>
                                </div>
                                <div class="d-flex">
                                    <p>Kinderen 4 tot 10 jaar </p>
                                    <span class="ml-auto text-danger">&euro;16,00*</span>
                                </div>
                              <p class="font-weight-bold">Lunch (12:00 tot 16:00)
                                <small>max. 2 uur per reservering</small>
                              </p>
                                <div class="d-flex">
                                  <p>Maandag t/m Zondag</p>
                                  <span class="ml-auto text-danger">&euro;25,00*</span>
                                </div>
                                <p class="text-muted">*prijzen zijn excl. drank</p>
                                <b-button to="/menukaart" size="sm" pill variant="danger"
                                          class="text-uppercase font-weight-bold p-3 mt-3">
                                    Bekijk ons AYCE menu
                                </b-button>
                            </div>
                        </b-col>
                        <b-col sm="12" lg="6" class="d-flex justify-content-center align-items-center p-5 p-sm-2"
                               style="background-color: #2f3542">
                            <div class="p-5">
                                <div class="section-title">
                                    <h1 class="text-white">Reserveringen</h1>
                                    <h4>Geopend</h4>
                                </div>
<!--                                <p class="text-white">-->
<!--                                    Vanaf 5 juni zijn onze deuren weer geopend, het is mogelijk om weer in het restaurant gezellig te-->
<!--                                    komen eten. Wij volgen hierbij nog wel de richtlijnen van het RIVM.-->
<!--                                </p>-->
                                <p>
                                  Wij verwelkomen u dagelijks van 12:00 tot 22:00 uur. U kunt al vanaf 12:00 telefonisch of online bestellen, ook kunt u terecht voor ons lunch All-You-Can-Eat arrangement.
                                </p>
<!--                                <p class="text-white">-->
<!--                                  Een tafel reserveren is <b>verplicht</b>. Daarnaast is het na bezoek ook verplicht om een telefoonnummer achter te laten-->
<!--                                    voor eventuele contactonderzoeken. Om veiligheid te garanderen mogen wij maximaal-->
<!--                                    50 personen binnen laten hierdoor werken wij in 3 tijdsloten: <br><br>-->
<!--                                    Maandag t/m Donderdag: <br>-->
<!--                                    1e shift - 17:00 tot 19.30 <br>-->
<!--                                    2e shift - 19:30 tot 22:00 <br><br>-->
<!--                                    Vrijdag t/m Zondag <br>-->
<!--                                    1e shift - 16:00 tot 18.00 <br>-->
<!--                                    2e shift - 18:00 tot 20:00 <br>-->
<!--                                    3e shift - 20:00 tot 22:00 <br>-->
<!--                                </p>-->
<!--                                <p class="text-white">-->
<!--                                    Op verzoek van vele klanten breiden we tijdelijk onze bezorgservice uit in de-->
<!--                                    volgende postcodes:<br>-->
<!--                                    Lombardijen: <b>3076</b><br>-->
<!--                                    Ijsselmonde: <b>3077, 3078, 3079</b><br>-->
<!--                                    Hendrik Ido Ambacht: <b>3340, 3341, 3342, 3343, 3344</b><br>-->
<!--                                    Barendrecht: <b>2991, 2992, 2993, 2994</b><br>-->
<!--                                </p>-->
                                <p>
                                    Om een plek te garanderen adviseren we ruim op tijd te reserveren om teleurstellingen te voorkomen.
                                </p>
<!--                                <p>-->
<!--                                    In deze wijken een minimaal bestelbedrag van €30,- en bezorgkosten €2,-.-->
<!--                                </p>-->
                                <b-button href="tel:0180 418 414" pill size="sm" variant="danger"
                                          class="text-uppercase font-weight-bold p-3 mt-3">
                                    Tafel Reserveren
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </section>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Home',
    }
</script>

<style scoped>
    .home .bgimage {
        background: url('../assets/header/banner-home.jpg');
        background-size: cover;
    }
</style>
