<template>
    <!-- Footer -->
    <footer class="page-footer">

        <b-container fluid>
            <b-row class="image d-flex align-items-center">
                <b-col cols="12">
                    <b-container>
                        <address class="text-white">
                            <strong>Sushi King Mountain Spring</strong> <br/>
                            Groen van Prinstererweg 19-21 <br/>
                            2982VA, Ridderkerk <br/>
                            <abbr title="Phone">TEL:</abbr> 0180 418 414
                        </address>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>

        <b-container>
            <b-row class="bg-white py-3 font-weight-bold">
                <b-col cols="12">
                    <b-navbar class="d-flex flex-wrap justify-content-center p-0" type="light">
                        <b-navbar-nav class="text-uppercase flex-wrap justify-content-center">
                            <b-nav-item to="/privacy-statement">
                                Privacy statement
                            </b-nav-item>
                        </b-navbar-nav>
                        <b-navbar-nav class="ml-lg-auto text-uppercase">
                            <b-nav-item href="tel:0180 418 414">
                                0180 418 414
                            </b-nav-item>
                            <b-nav-item target="_blank" href="https://facebook.com/mountainspringsushiking/">
                                Facebook
                            </b-nav-item>
                        </b-navbar-nav>
                    </b-navbar>
                </b-col>
            </b-row>
        </b-container>

        <b-container fluid>
            <!-- Copyright -->
            <b-row class="footer-copyright py-3" style="background-color: #f7f1e3">
                <b-col cols="12">
                    <b-container>
                        <div class="d-flex flex-wrap justify-content-center align-items-center">
                            <b-img src="@/assets/logo-transparent.png" class="img-fluid footer-logo" alt="Logo"/>
                            <span class="ml-auto text-center mt-3">
                            © {{ new Date().getFullYear() }} Copyright:
                            <a href="https://www.mountainspring-sushiking.nl/">
                                Mountain Spring Sushi King
                            </a>
                            Made by:
                            <a href="https://year3000.nl/">
                                Year 3000 ❤️
                            </a>
                            </span>
                        </div>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>


    </footer>
</template>

<style scoped>
    .page-footer {
        font-size: 13px;
    }

    .page-footer .image {
        height: 200px;
        background: url('../assets/header/banner-menu-sub.jpg');
        background-size: cover;
    }

    .page-footer .image::before {
        background-color: rgba(47, 53, 66, 0.9);
        content: '';
        display: block;
        height: 200px;
        position: absolute;
        width: 100%;
    }

    .footer-logo {
        width: 15%;
    }

    @media only screen and (max-width: 600px) {
        .footer-logo {
            width: 50%;
        }
    }

</style>

<script>
    export default {
        name: 'FooterBar'
    }
</script>