<template>
    <div id="app">
        <navigation-bar/>
        <router-view></router-view>
        <cart/>
        <footer-bar/>
    </div>
</template>

<script>
    import NavigationBar from './components/NavigationBar'
    import FooterBar from './components/FooterBar'
    import Cart from './components/Cart'

    export default {
        components: {NavigationBar, FooterBar, Cart}
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    body {
        position: relative;
    }

    section.bgimage {
        min-height: 800px;
    }

    .section-1 {
        background-color: #f7f1e3;
        color: #2f3542;
    }

    .section-2 {
        background-color: #dfe4ea;
    }

    .bgimage {
        background-size: cover;
    }

    .bgimage::before {
        background-color: rgba(47, 53, 66, 0.7);
        content: '';
        display: block;
        height: 800px;
        position: absolute;
        width: 100%;
    }

    h1, h4 {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 50px !important;
    }

    h4 {
        font-size: 20px !important;
        font-weight: 700 !important;
    }

    p {
        color: #747d8c;
        font-size: 17px !important;
        font-family: 'Hind Madurai', sans-serif;
    }

    .bgimage h1 {
        color: white;
    }

    .section-title h4, .section-title h4 {
        color: #ff6348;
        padding-bottom: 25px;
    }
</style>
