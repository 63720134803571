import 'es6-promise/auto'

import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Install BootstrapVue
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Install vue-slick-carousel
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

// Install vue-parallax-js
import VueParallaxJs from 'vue-parallax-js'

Vue.use(VueParallaxJs)

// Install date time picker
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)

// Install Vuelidate [Form validation]
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

// Import stores
import store from './store'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
