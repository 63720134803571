<template>
    <div class="checkout">
        <b-col cols="12" class="mb-3">
            <b-button @click="$emit('togglePage', 'cart')"
                      variant="outline-primary"
                      class="mb-2"
            >
                <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
                Terug naar winkelmandje
            </b-button>
        </b-col>
        <b-col cols="12">
            <b-form class="row" @submit.stop.prevent="onSubmit">
                <b-col cols="12">
                    <b-row v-if="this.delivery">
                        <b-col cols="12">
                            <h4 class="text-muted">Waar kunnen we bezorgen?</h4>
                            <hr>
                        </b-col>
                        <b-col cols="12" lg="8">
                            <b-form-group label="Straatnaam en huis nummer*" label-for="street">
                                <b-form-input
                                        id="street"
                                        name="street"
                                        v-model="$v.form.street.$model"
                                        :state="validateState('street')"
                                        aria-describedby="input-street-live-feedback"
                                        placeholder="Straatnaam en huis nummer"
                                />
                                <b-form-invalid-feedback
                                        id="input-street-live-feedback"
                                >
                                    Dit veld is verplicht
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group label="Postcode*" label-for="zipcode">
                                <b-form-input
                                        id="zipcode"
                                        name="zipcode"
                                        v-model="$v.form.zipcode.$model"
                                        :state="validateState('zipcode')"
                                        aria-describedby="input-zipcode-live-feedback"
                                        readonly
                                        placeholder="Postcode"
                                />
                                <b-form-invalid-feedback
                                        id="input-zipcode-live-feedback"
                                >
                                    Dit veld is verplicht
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="8">
                            <b-form-group label="Plaats*" label-for="city">
                                <b-form-input
                                        id="city"
                                        name="city"
                                        v-model="$v.form.city.$model"
                                        :state="validateState('city')"
                                        aria-describedby="input-city-live-feedback"
                                        placeholder="Plaatsnaam"
                                />
                                <b-form-invalid-feedback
                                        id="input-city-live-feedback"
                                >
                                    Dit veld is verplicht
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="mt-2" cols="12">
                    <h4 class="text-muted">Contact gegevens.</h4>
                    <hr>
                </b-col>
                <b-col lg="6">
                    <b-form-group
                            label="Naam*"
                            label-for="name"
                    >
                        <b-form-input
                                id="name"
                                name="name"
                                v-model="$v.form.name.$model"
                                :state="validateState('name')"
                                aria-describedby="input-name-live-feedback"
                                placeholder="Naam"
                        />
                        <b-form-invalid-feedback
                                id="input-name-live-feedback"
                        >
                            Dit veld is verplicht
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group
                            label="Email adres*"
                            label-for="email"
                    >
                        <b-form-input
                                id="email"
                                name="email"
                                type="email"
                                v-model="$v.form.email.$model"
                                :state="validateState('email')"
                                aria-describedby="input-email-live-feedback"
                                placeholder="Email"
                        />
                        <b-form-invalid-feedback
                                id="input-email-live-feedback"
                        >
                            Dit veld is verplicht
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group
                            label="Telefoonnummer*"
                            label-for="phone-number"
                    >
                        <b-form-input
                                id="phone-number"
                                name="phone_numer"
                                v-model="$v.form.phone_number.$model"
                                :state="validateState('phone_number')"
                                aria-describedby="input-phone-number-live-feedback"
                                placeholder="Telefoon nummer"
                        />
                        <b-form-invalid-feedback
                                id="input-phone-number-live-feedback"
                        >
                            Dit veld is verplicht
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group
                            label="Bedrijfsnaam"
                            label-for="company"
                    >
                        <b-form-input
                                id="company"
                                v-model="form.company_name"
                                placeholder="Bedrijfsnaam"
                        />
                    </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="12">
                    <h4 class="text-muted">Hoelaat wil je eten?</h4>
                    <hr>
                </b-col>
                <b-col lg="12">
                    <b-form-group id="input-group-3">
                        <b-form-checkbox
                                v-model="deliveryTimeCheck"
                                @change="$v.form.delivery_time.$model = null">
                            Zo snel mogelijk
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col v-if="!deliveryTimeCheck" lg="12">
                    <b-form-group id="input-group-4">
                        <datetime
                                type="time"
                                :input-class="`form-control ${!$v.form.delivery_time.required ? 'is-invalid' : 'is-valid'}`"
                                placeholder="Kies een tijd"
                                :state="validateState('delivery_time')"
                                v-model="$v.form.delivery_time.$model"
                                :minute-step="15"
                                :min-datetime="minimalOrderTime"
                                :max-datetime="closingTime.format('HH:mm:ss')"
                                aria-describedby="input-delivery-time-live-feedback"
                        >
                            <template slot="button-confirm">
                                Bevestigen
                            </template>
                        </datetime>
                        <div v-if="!$v.form.delivery_time.required"
                             id="input-delivery-time-live-feedback"
                             class="invalid-feedback d-block">
                            Dit veld is verplicht
                        </div>
                    </b-form-group>
                </b-col>
                <b-col lg="12">
                    <b-form-group
                        id="input-group-5"
                        label="Opmerkingen voor ons?"
                        label-for="comments"
                    >
                        <b-form-textarea
                                id="textarea"
                                v-model="form.comments"
                                rows="3"
                                max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col lg="12">
                    <b-button
                            block
                            type="submit"
                            class="mb-3"
                            variant="primary">
                        Betalen
                    </b-button>
                </b-col>
            </b-form>
        </b-col>

    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import {validationMixin} from "vuelidate";
    import {required} from "vuelidate/lib/validators";
    import {Datetime} from 'vue-datetime';

    export default {
        name: 'Checkout',
        props: {
            'zipcode': String,
            'closingTime': Object,
            'currentDate': String,
            'cart': Array,
            'deliveryPrice': Number,
            'delivery': Boolean,
        },
        components: {
            Datetime,
        },
        mixins: [validationMixin],
        data() {
            return {
                deliveryTimeCheck: true,
                form: {
                    street: null,
                    city: null,
                    name: null,
                    email: null,
                    phone_number: null,
                    company_name: null,
                    comments: null,
                    zipcode: this.zipcode,
                    order_lines: this.cart,
                    delivery_time: null,
                    delivery_price: this.deliveryPrice,
                    delivery: this.delivery
                }
            }
        },
        validations() {

            // Default validations
            let validation = {
                form: {
                    street: {},
                    city: {},
                    name: {
                        required
                    },
                    email: {
                        required
                    },
                    phone_number: {
                        required
                    },
                    zipcode: {},
                    delivery_time: {}
                }
            }

            // Delivery time checkbox is unchecked -> choose time
            if(!this.deliveryTimeCheck) {
                validation.form.delivery_time = {
                    required
                }
            }

            if(this.delivery) {
                validation.form.street = {
                    required
                }
                validation.form.city = {
                    required
                }
                validation.form.zipcode = {
                    required
                }
            }

            return validation
        },
        computed: {
            minimalOrderTime() {
                // Minimal order time for the time picker
                let utc = moment.utc(this.currentDate).toDate()

                // Default added hours
                let addedHours = 1

                // Get the minutes
                let minutes = moment(utc).local().format('mm')

                // Find the nearest quarter hour
                let nearestQuarter = (Math.ceil((minutes / 15)) * 15)

                // Round to zero and add 2 hours when its for example 19:46
                if(nearestQuarter > 45) {
                    nearestQuarter = '00'
                    addedHours = 2
                }

                return moment(utc).add(addedHours, 'hours').local().format(`HH:${nearestQuarter}:ss`)
            },
        },
        methods: {
            validateState(name) {
                const {$dirty, $error} = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            onSubmit() {

                this.$v.form.$touch();

                if (this.$v.form.$anyError) {
                    return
                }

                // POST to order API
                axios.post(`${process.env.VUE_APP_API_URL || 'http://localhost:3000/'}api/orders/`, this.form).then((response) => {
                    // Handle success
                    const {checkout} = response.data.data
                    window.location.href = checkout;
                }).catch((error) => {
                    console.log(error)
                })
            },
        },
    }
</script>