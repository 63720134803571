<template>
    <b-navbar
            id="navbar"
            fixed="top"
            toggleable="lg"
            class="pt-0 mt-0 w-100"
            :variant="variantScheme"
            :type="typeScheme"
    >
        <b-navbar-brand to="/" style="width: 40%;">
            <img src="@/assets/logo-transparent.png" class="mt-2" :class="classSize" alt="Logo">
        </b-navbar-brand>

        <b-button
                v-b-toggle.sidebar-right
                class="d-block d-lg-none mt-2 mr-2 ml-auto"
                squared
                size="sm"
        >
            Winkelmandje
            <b-badge variant="light">{{this.cartSize}}</b-badge>
        </b-button>

        <b-navbar-toggle class="mt-2" target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="text-uppercase font-weight-bold ml-auto my-3">
                <b-nav-item to="/">Home</b-nav-item>
                <b-nav-item to="/menukaart">Menukaart</b-nav-item>
                <b-nav-item to="/afhalen-en-bezorgen">Afhalen en Bezorgen</b-nav-item>
                <b-nav-item to="/contact">Contact</b-nav-item>
                <b-button v-b-toggle.sidebar-right class="mt-3 mx-lg-3 mt-lg-0 d-none d-lg-block">
                    Winkelmandje
                    <b-badge variant="light">{{this.cartSize}}</b-badge>
                </b-button>
            </b-navbar-nav>
        </b-collapse>

    </b-navbar>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "NavigationBar",
        data() {
            return {
                showNavbar: true,
                lastScrollPosition: 0,
            }
        },
        computed: {
            variantScheme() {
                return this.showNavbar ? 'faded' : 'light';
            },
            typeScheme() {
                return this.showNavbar ? 'dark' : 'light';
            },
            classSize() {
                return this.showNavbar ? 'w-50' : 'smaller-logo';
            },
            ...mapGetters([
                "cartSize",
            ]),
        },
        methods: {
            onScroll() {
                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
                if (currentScrollPosition >= 0) {
                    this.showNavbar = false
                }
                if (currentScrollPosition === 0) {
                    this.showNavbar = true
                }
            },
        },
        mounted() {
            this.onScroll()
            window.addEventListener('scroll', this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll)
        }
    }
</script>

<style scoped>
    #navbar {
        transition: 0.5s;
    }

    img {
        transition: 0.5s;
    }

    .smaller-logo {
        width: 25%;
    }

    @media only screen and (max-width: 600px) {
        .smaller-logo {
            width: 50%;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        .smaller-logo {
            width: 35%;
        }
    }
</style>