import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/menukaart',
        name: 'Menu',
        component: () => import('../views/Menu.vue')
    },
    {
        path: '/afhalen-en-bezorgen',
        name: 'Delivery',
        component: () => import('../views/Delivery.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    {
        path: '/gegevens',
        name: 'CustomerData',
        component: () => import('../views/CustomerData.vue')
    },
    {
        path: '/bestelling/:id',
        name: 'PlacedOrder',
        component: () => import('../views/PlacedOrder.vue')
    },
    {
        path: '/privacy-statement',
        name: 'PrivacyStatement',
        component: () => import('../views/Privacy.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (!to.hash) {
            return {x: 0, y: 0}
        }
    }
})

export default router
