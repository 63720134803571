import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        products: null,
        categories: null,
        zipcodes: null,
        cart: [],
        cartOpen: false,
    },
    getters: {
        products: (state) => {
            return state.products
        },
        categories: (state) => {
            return state.categories
        },
        zipcodes: (state) => {
            return state.zipcodes
        },
        cartSize: (state) => {
            return state.cart.reduce((total, product) => {
                return total + ((product.quantity))
            }, 0)
        },
        cartOpen: (state) => {
            return state.cartOpen;
        },
        cartTotalAmount: (state) => {
            return state.cart.reduce((total, product) => {

                // Add all extra_price if any
                let extra_price = JSON.parse(product.editedRules).reduce((sum, rule) => {
                    return sum + rule.extra_price
                }, 0)

                // Return the total price
                return total + ((product.price * product.quantity) + extra_price);
            }, 0);
        },
    },
    mutations: {
        setUpProducts: (state, productsPayload) => {
            // Sets the state's products property to the products array received as payload
            state.products = productsPayload;
        },
        setUpCategories: (state, categoriesPayload) => {
            // Sets the state's categories property to the categories array received as payload
            state.categories = categoriesPayload;
        },
        setUpZipcodes: (state, zipcodesPayload) => {
            // Sets the state's products property to the products array received as payload
            state.zipcodes = zipcodesPayload;
        },
        cartOpen: (state, cartOpen) => {
            // Sets the state's products property to the products array received as payload
            state.cartOpen = cartOpen;
        },
        addToCart: (state, {productId, editedRules}) => {

            // Find the product in the products list
            let product = state.products.find((product) => product._id === productId);

            // Find the product in the cart list
            let cartProduct = state.cart.find((product) => product._id === productId);

            /** If there are any productRules check if they already
             * exist in the cart and is the same as other lines */
            if (editedRules.length > 0) {
                cartProduct = state.cart.find((product) => product.editedRules === JSON.stringify(editedRules))
            }

            if (cartProduct) {
                cartProduct.quantity++;

            } else {
                state.cart.push({
                    // Product newly added to cart
                    ...product,
                    editedRules: JSON.stringify(editedRules),
                    quantity: 1,
                });
            }
        },
        removeFromCart: (state, {productId, editedRules}) => {

            // Find the product in the cart list
            let cartProduct = state.cart.find((product) => product._id === productId);

            /** If there are any productRules check if they already
             * exist in the cart and is the same as other lines */
            if (editedRules.length > 0) {
                cartProduct = state.cart.find((product) => product.editedRules === JSON.stringify(editedRules))
            }

            cartProduct.quantity--;
        },
        deleteFromCart: (state, {productId, editedRules}) => {

            // Find the product index in the cart list
            let cartProductIndex = state.cart.findIndex((product) => product._id === productId);

            /** If there are any productRules check if they already
             * exist in the cart and is the same as other lines */
            if (editedRules.length > 0) {
                cartProductIndex = state.cart.findIndex((product) => product.editedRules === JSON.stringify(editedRules))
            }

            // Remove the product from the cart
            state.cart.splice(cartProductIndex, 1);
        },
    },
    actions: {
        fetchProducts: ({commit}) => {
            axios.get(`${process.env.VUE_APP_API_URL || 'http://localhost:3000/'}api/products/`).then((response) => {
                const {data} = response.data
                commit('setUpProducts', data)
            })
        },
        fetchCategories: ({commit}) => {
            axios.get(`${process.env.VUE_APP_API_URL || 'http://localhost:3000/'}api/categories/`).then((response) => {
                const {data} = response.data
                commit('setUpCategories', data)
            })
        },
        fetchZipcodes: ({commit}) => {
            axios.get(`${process.env.VUE_APP_API_URL || 'http://localhost:3000/'}api/zipcodes/`).then((response) => {
                const {data} = response.data
                commit('setUpZipcodes', data)
            })
        },
        addToCart: ({commit}, productId) => {
            commit("addToCart", productId)
        },
        removeFromCart: ({commit}, productId) => {
            commit("removeFromCart", productId);
        },
        deleteFromCart: ({commit}, productId) => {
            commit("deleteFromCart", productId);
        },
        cartOpen: ({commit}, cartOpen) => {
            commit("cartOpen", cartOpen)
        }
    }
})